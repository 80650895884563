import { combineReducers } from 'redux';
import appSCReducer from './reducer/appSCSlice';
import appReducer from './reducer/appSlice';
import loginReducer from '../features/Login/loginSlice';
import overviewReducer from '../features/Overview/overviewSlice';
import manageBannerTableReducer from '../features/Banner/ManageBanner/manageBannerTableSlice';
import manageUserTableReducer from '../features/User/ManageUser/manageUserTableSlice';
import manageCategoryTableReducer from '../features/Category/ManageCategory/manageCategoryTableSlice';
import manageReviewTableReducer from '../features/Review/ManageReview/manageReviewTableSlice';
import manageProductTableReducer from '../features/Product/ManageProduct/manageProductTableSlice';
import manageSubCategoryTableReducer from '../features/SubCategory/ManageSubCategory/manageSubCategoryTableSlice';
import manageDiscountTableReducer from '../features/Discount/ManageDiscount/manageDiscountTableSlice';
import manageOrderTableReducer from '../features/Order/ManageOrder/manageOrderTableSlice';
import manageCustomerTableReducer from '../features/Customer/ManageCustomer/manageCustomerTableSlice';
import manageTestimonialTableReducer from '../features/Testimonial/ManageTestimonial/manageTestimonialTableSlice';
import manageFailedPaymentTableReducer from '../features/FailedPayment/ManageFailedPayment/manageFailedPaymentTableSlice';
import managePincodeTableReducer  from '../features/Pincode/ManagePincode/managePincodeTableSlice';
import managePincodeAlertTableReducer from '../features/PincodeAlert/ManagePincodeAlert/managePincodeAlertTableSlice';
import manageStatusTableReducer from '../features/Status/ManageStatus/manageStatusTableSlice';
import manageProductWiseReportTableReducer from '../features/Report/ManageProductWiseReport/manageProductWiseReportTableSlice';
import manageEstimationTableReducer from '../features/Estimation/ManageEstimation/manageEstimationTableSlice';


const rootReducer = combineReducers({
  appReducer,
  appSCReducer,
  loginReducer,
  overviewReducer,
  manageUserTableReducer,
  manageBannerTableReducer,
  manageCategoryTableReducer,
  manageReviewTableReducer,
  manageProductTableReducer,
  manageSubCategoryTableReducer,
  manageDiscountTableReducer,
  manageOrderTableReducer,
  manageCustomerTableReducer,
  manageTestimonialTableReducer,
  manageFailedPaymentTableReducer,
  managePincodeTableReducer,
  managePincodeAlertTableReducer,
  manageStatusTableReducer,
  manageProductWiseReportTableReducer,
  manageEstimationTableReducer,
});

// const clearReducer = (state, action) => {
//   console.log('clear reducer called.......................');
//   if (action.type === 'clearReduxCache') {
//     state = undefined;
//   }
//   return rootReducer(state, action);
// };

export default rootReducer