import { lazy } from 'react';
import { Navigate, useLocation } from "react-router-dom";

import SubLayout from '../features/SubLayout/SubLayout';
import Loadable from '../components/Loadable/Loadable';

// dashboard routing
const PrintOrder = Loadable(lazy(() => import('../features/PrintOrder/PrintOrder')));
const PrintEstimation = Loadable(lazy(() => import('../features/Estimation/PrintEstimation/PrintEstimation')));

const SubRoutes = (user) => {
    const location = useLocation();
    return (
        {
            path: '/',
            element: user ? <SubLayout /> : <Navigate to="/login" state={{ from: location }} />,
            children: [
                {
                     path: '/print-order',
                     element: <PrintOrder/>
                 },
                 {
                    path: '/print-estimation',
                    element: <PrintEstimation/>
                },
            ]
        }
    )
}


export default SubRoutes;
