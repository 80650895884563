import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice';
import { isArray } from '../../../Utils/commonUtils';
import { FLOW_TYPE } from '../../../Utils/constants';

export const fetchEstimationList = createAsyncThunk('/fetchManageOrderList', async ({ data, params }, { dispatch }) => {
  params.page = params.page + 1;
  let result = { total: 0, data: [] }
  return await axiosPrService.post('/master/search/estimation', data, { params }, { dispatch })
    .then(res => {
      if (res.status === 200 && isArray(res.data)) {
        console.log("Estimation list response >>>>>",res.data);
        for (var i = 0; i < res.data.length; i++) {
            res.data[i].orderDate = res.data[i].orderDate.split("T")[0];
            if(res.data[i].assignedToUser!=null){
              res.data[i].assignedToUser= res.data[i].assignedToUser.userName;
            }else{
              res.data[i].assignedToUser= "Not Assigned"              
            }
        }  
        result = { total: Number(res.headers['x-total-records']), data: res.data }
        return result;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});

export const fetchEstimationListTableColumnConfig = createAsyncThunk('/fetchEstimationTableConfig', async ({ params }, { dispatch }) => {
  let result = [];
  return await axiosPrService.get('/configuration/estimation', { params }, { dispatch })
    .then(res => {
      if (res.status === 200 && isArray(res.data)) {
        console.log("configuration estimation :::: ",res.data)
        let result = res.data[0].fields;
        console.log(result);
        return result
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});



const initialState = {
  // table 
  data: [],
  loading: false,
  error: null,

  // columns 
  columnsOriginalResponse: [],
  columnsError: null,

  page: 0,
  total: 0,
  rowsPerPage: 10,
  sortBy: "",
  sortKey: "",
  appliedFilterList: [],

  flow: FLOW_TYPE.NEW,
  selectedEstimationData: null,
};

export const manageEstimationTableReducer = createSlice({
  name: 'manageEstimationTableReducer',
  initialState,
  reducers: {
    updateTableState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
  },
  extraReducers: {
    [fetchEstimationList.pending]: (state, action) => {
      state.loading = true
    },
    [fetchEstimationList.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data || [];
      state.total = action.payload?.total || 0;
    },
    [fetchEstimationList.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    },

    [fetchEstimationListTableColumnConfig.pending]: (state, action) => {
      state.loading = true
    },
    [fetchEstimationListTableColumnConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = action.payload
    },
    [fetchEstimationListTableColumnConfig.rejected]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = [];
      state.columnsError = true;
    },
  }
});

export const { updateTableState } = manageEstimationTableReducer.actions;

export default manageEstimationTableReducer.reducer;
