// import PrintOrder from "../PrintOrder/PrintOrder";



// export default function SubLayout() {

//     return (
//         <PrintOrder />
//     )

// }

import { Outlet } from 'react-router-dom';

export default function SubLayout() {
    return (
        <>
            <Outlet />
        </>
    );
}