import { lazy } from 'react';
import { Navigate, useLocation } from "react-router-dom";

import MainLayout from '../features/MainLayout/MainLayout';
import Loadable from '../components/Loadable/Loadable';

// dashboard routing
const Overview = Loadable(lazy(() => import('../features/Overview/Overview')));
const ManageBanner = Loadable(lazy(() => import('../features/Banner/ManageBanner/ManageBanner')));
const CreateBanner = Loadable(lazy(() => import('../features/Banner/CreateBanner/CreateBanner')));
const ManageUser = Loadable(lazy(() => import('../features/User/ManageUser/ManageUser')));
const CreateUser = Loadable(lazy(() => import('../features/User/CreateUser/CreateUser')));
const Report = Loadable(lazy(() => import('../features/Report/Report')));
const Settings = Loadable(lazy(() => import('../features/Settings/Settings')));
const Help = Loadable(lazy(() => import('../features/Help/Help')));
const ManageCategory = Loadable(lazy(() => import('../features/Category/ManageCategory/ManageCategory')));
const CreateCategory = Loadable(lazy(() => import('../features/Category/CreateCategory/CreateCategory')));
const ManageProduct = Loadable(lazy(() => import('../features/Product/ManageProduct/ManageProduct')));
const CreateProduct = Loadable(lazy(() => import('../features/Product/CreateProduct/CreateProduct')));
const ManageReview = Loadable(lazy(() => import('../features/Review/ManageReview/ManageReview')));
const UpdateReview = Loadable(lazy(() => import('../features/Review/EditReview/EditReview')));
const ManageSubCategory = Loadable(lazy(() => import('../features/SubCategory/ManageSubCategory/ManageSubCategory')));
const CreateSubCategory = Loadable(lazy(() => import('../features/SubCategory/CreateSubCategory/CreateSubCategory')));
const ManageDiscount = Loadable(lazy(() => import('../features/Discount/ManageDiscount/ManageDiscount')));
const CreateDiscount = Loadable(lazy(() => import('../features/Discount/CreateDiscount/CreateDiscount')));
const ManageOrder = Loadable(lazy(() => import('../features/Order/ManageOrder/ManageOrder')));
const CreateOrder = Loadable(lazy(() => import('../features/Order/CreateOrder/CreateOrder')));
const UpdateOrder = Loadable(lazy(() => import('../features/Order/UpdateOrder/UpdateOrder')));
//const ViewOrder = Loadable(lazy(() => import('../features/ViewOrder/ViewOrder')));
//const PrintOrder = Loadable(lazy(() => import('../features/PrintOrder/PrintOrder')));
const ManageCustomer = Loadable(lazy(() => import('../features/Customer/ManageCustomer/ManageCustomer')));
const EditStore = Loadable(lazy(() => import('../features/Store/EditStore/EditStore')));
const BulkUpload = Loadable(lazy(() => import('../features/Product/BulkUpload/BulkUpload')));
const ManagePincodeAlert = Loadable(lazy(() => import('../features/PincodeAlert/ManagePincodeAlert/ManagePincodeAlert')));
const UpdatePincodeAlert = Loadable(lazy(() => import('../features/PincodeAlert/UpdatePincodeAlert/UpdatePincodeAlert')));
const ManagePincode = Loadable(lazy(() => import('../features/Pincode/ManagePincode/ManagePincode')));
const CreatePincode = Loadable(lazy(() => import('../features/Pincode/CreatePincode/CreatePincode')));
const ModifyTheme = Loadable(lazy(() => import('../features/ModifyTheme/ModifyTheme')));
const ManageTestimonial = Loadable(lazy(() => import('../features/Testimonial/ManageTestimonial/ManageTestimonial')));
const CreateTestimonial = Loadable(lazy(() => import('../features/Testimonial/CreateTestimonial/CreateTestimonial')));
const ManageFailedPayment = Loadable(lazy(() => import('../features/FailedPayment/ManageFailedPayment/ManageFailedPayment')));
const SalesReport = Loadable(lazy(() => import('../features/Report/SalesReport/SalesReport')));
const OrderReport = Loadable(lazy(() => import('../features/Report/OrderReport/OrderReport')));
const ProductReport = Loadable(lazy(() => import('../features/Report/ProductReport/ProductReport')));
const ProductWiseReport = Loadable(lazy(() => import('../features/Report/ManageProductWiseReport/ManageProductWiseReport')));
const ManageStatus = Loadable(lazy(() => import('../features/Status/ManageStatus/ManageStatus')));
const CreateStatus = Loadable(lazy(() => import('../features/Status/CreateStatus/CreateStatus')));
const EditTax = Loadable(lazy(() => import('../features/Tax/EditTax/EditTax')));
const ManageEstimation = Loadable(lazy(() => import('../features/Estimation/ManageEstimation/ManageEstimation')));
const CreateEstimation = Loadable(lazy(() => import('../features/Estimation/CreateEstimation/CreateEstimation')));
const UpdateEstimation = Loadable(lazy(() => import('../features/Estimation/UpdateEstimation/UpdateEstimation')));

const MainRoutes = (user) => {
    const location = useLocation();
    return (
        {
            path: '/',
            element: user ? <MainLayout /> : <Navigate to="/login" state={{ from: location }} />,
            children: [
                {
                    path: '/',
                    element: <Overview />,
                },
                {
                    path: '/overview',
                    element: <Overview />
                },
                {
                    path: '/manage-user',
                    element: <ManageUser />
                },
                {
                    path: '/create-user',
                    element: <CreateUser />
                },
                {
                    path: '/report',
                    element: <Report />
                },
                {
                    path: '/settings',
                    element: <Settings />
                },
                {
                    path: '/help',
                    element: <Help />
                },
                {
                    path: '/manage-banner',
                    element: <ManageBanner/>
                },
                {
                    path: '/create-banner',
                    element: <CreateBanner/>
                },
                {
                    path: '/manage-category',
                    element: <ManageCategory/>
                },
                {
                    path: '/create-category',
                    element: <CreateCategory/>
                },
                {
                    path: '/manage-sub-category',
                    element: <ManageSubCategory/>
                },
                {
                    path: '/create-sub-category',
                    element: <CreateSubCategory/>
                },
                {
                    path: '/manage-review',
                    element: <ManageReview/>
                },
                {
                    path: '/edit-review',
                    element: <UpdateReview/>
                },
                {
                    path: '/manage-product',
                    element: <ManageProduct/>
                },
                {
                    path: '/create-product',
                    element: <CreateProduct/>
                },
                {
                    path: '/manage-discount',
                    element: <ManageDiscount/>
                },
                {
                    path: '/create-discount',
                    element: <CreateDiscount/>
                },
                 {
                    path: '/manage-order',
                    element: <ManageOrder/>
                },
                {
                    path: '/create-order',
                    element: <CreateOrder/>
                },
                {
                    path: '/update-order',
                    element: <UpdateOrder/>
                },
                // {
                //     path: '/view-order',
                //     element: <ViewOrder/>
                // },
                // {
                //      path: '/print-order',
                //      element: <PrintOrder/>
                //  },
                {
                    path: '/manage-customer',
                    element: <ManageCustomer/>
                },
                {
                    path: '/edit-store',
                    element: <EditStore/>
                },
                {
                    path: '/bulk-upload',
                    element: <BulkUpload/>
                },
                {
                    path: '/pincode-alerts',
                    element: <ManagePincodeAlert/>
                },
                {
                    path: '/update-pincode-alert',
                    element: <UpdatePincodeAlert/>
                },
                {
                    path: '/manage-pincode',
                    element: <ManagePincode/>
                },
                {
                    path: '/create-pincode',
                    element: <CreatePincode/>
                },
                {
                    path: '/modifyTheme',
                    element: <ModifyTheme/>
                },
                {
                    path: '/manage-testimonial',
                    element: <ManageTestimonial/>
                },
                {
                    path: '/create-testimonial',
                    element: <CreateTestimonial/>
                },
                {
                    path: '/manage-failedpayment',
                    element: <ManageFailedPayment/>
                },
                {
                    path: '/sales-report',
                    element: <SalesReport/>
                },
                {
                    path: '/order-report',
                    element: <OrderReport/>
                },
                {
                    path: '/product-report',
                    element: <ProductReport/>
                },
                {
                    path: '/product-wise-report',
                    element: <ProductWiseReport/>
                },
                {
                    path: '/manage-status',
                    element: <ManageStatus/>
                },
                {
                    path: '/create-status',
                    element: <CreateStatus/>
                },
                {
                    path: '/edit-tax',
                    element: <EditTax/>
                },
                {
                    path: '/manage-estimation',
                    element: <ManageEstimation/>
                },
                {
                    path: '/create-estimation',
                    element: <CreateEstimation/>
                },
                {
                    path: '/update-estimation',
                    element: <UpdateEstimation/>
                },
            ]
        }
    )
}


export default MainRoutes;
